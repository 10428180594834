import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, sectionID, classID} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getClassName, longDate} from '../component/globalFunction'

import { useDispatch, useSelector } from 'react-redux'

 const CummulativeAssessment =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [assesmentGrade, setAssesmentGrade]=useState([])
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        isMusic:false,
        student:{value:'', label:'', studentName:''},
        classAssessment:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'},
    })

    const [csvFile, setCsvFile]=useState({
        studentCsv:'',
        fileName:'Please select .csv files (allowed file size 800KB)'
    })

    const steps = [{title: 'Standards Setup'}, {title: 'Score Entry'}]
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [examtype, setExamtype]=useState([])
    const [assessment, setAssessment]=useState([])
    const [category, setCategory]=useState([])
    const [classAssessment, setClassAssessment]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});

    const [musicTemplate, setMusicTemplate]=useState([])
    const [students, setStudents]=useState([])
const [activeStep, setActiveStep] = useState(1);

const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])

  
   
   
    
const fetchExamType =()=>{
    var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_cummulative_assessment_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active' and t.publish ='No' group by r.examCode order by exam ASC" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
setExamtype(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}



const fetchAssessment =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_cummulative_standard_settings/assessment/ID/1';
    axios.post(url, fd, config)
    .then(result =>setAssessment(result.data.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1)))
}

const fetchCategory =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_cummulative_standard_settings/category/ID/1';
    axios.post(url, fd, config)
    .then(result =>setCategory(result.data))
}



const fetchAssementGrade =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_cummulative_standard_settings/assesmentGrade/ID/1';
    axios.post(url, fd, config)
    .then(result =>setAssesmentGrade(result.data.sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1)))
}

const getCategory=(code)=>{
    const result = category && category.filter(list=>list.code===code);
  const answer = result.map((c)=>c.categoryName);
  return  answer
}


const getAssessmentName =(code, field)=>{
    const result = assessment.filter(item=>item.code===code)
    const answer = result.length!==0?result[0][field]:''
    return String(answer)
}




const getAssessmentMusic =(code, field)=>{
    const msk = musicTemplate.filter(item=>item.code===code)
    const answer = msk.length!==0?msk[0][field]:''
    return String(answer)
}



const fetchClassAssessment =(type)=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT  assessmentCode  from tbl_cummulative_assessment_result where examCode ='"+type+"' and sectionID ='"+sectionID+"' group by assessmentCode" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
    
        var option = result.data
        var response = []
        for (var i = 0; i < option.length; i++){
            response.push(
                {
                    code:option[i].assessmentCode,
                    categoryCode:getAssessmentName(option[i].assessmentCode, 'categoryCode'),
                    assessmentName:getAssessmentName(option[i].assessmentCode, 'assesmentName')
    
                })
        }
        setClassAssessment(response)
    }
    
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
    setNotice({...notice, isLoading: false})
    })
    }
    
    
    const DownloadStudentList =()=>{
        let error = {}; 
         
        if(result.examtype.length===0){
            error.examtype ='Please select exam type';
        } 
        if(result.student.value===''){
            error.student ='Please select Standards';
        } 
    
    
        setErrors(error)
    
        if(Object.keys(error).length === 0){
    
           document.getElementById('exportExcel').click()
    
        }else{
            window.scrollTo(0,0)
        }
    
    
    }


const fetchStudentList =(exam)=>{
setNotice({...notice, isLoading: true}) 


var sql ="SELECT s.admissionNumber, s.registerNumber, s.studentName, s.code as studentCode, a.assessmentCode, a.grade, a.code from tbl_students s, tbl_cummulative_assessment_result a where s.code = a.studentCode and s.admissionStatus ='Studying' and a.examCode ='"+exam+"' and a.sectionID ='"+sectionID+"' order by s.studentName"



const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0 && Array.isArray(response.data)){
        const list = response.data

        const onlyStudent =	list.map(e=>e['studentCode'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>list[e])
        .map(e=>list[e])

        
setStudents(onlyStudent)
setStudentList(response.data)

            }
            else{
                setStudentList([]) 
            }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
setNotice({...notice, isLoading: false})
})

}
  

const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
  

  
}


const handleSelectExam = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   fetchStudentList(option.value)
  
}


const handleChangeCheck =()=>{
    setResult({...result, isMusic:!result.isMusic, examtype:[], student:''})
    setStudentList([])
    setStudents([])
}



const handleChangeScore =(event, index)=>{

    const otherStudent = studentList.filter(item=>item.studentCode!==result.student.value)
    const oneStudent = studentList.filter(item=>item.studentCode===result.student.value)

    const student =JSON.parse(JSON.stringify(oneStudent))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student.concat(otherStudent)); 
       } 

       

 
const handleSubmit = event =>{
    event.preventDefault();    
    
    let errors = {};   
    if(studentList.length===0){
        errors.studentClass ='There are no student in the selected class';
    } 
    
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.student.value===''){
        errors.student ='Please select Student';
    }
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }

}




    function submit(){
    
setNotice({...notice,  isLoading: true})    

    const fd = new FormData(); 
    fd.append('jwt', Token);
   fd.append('assessment', JSON.stringify(studentList, null, 2));

   let url = ServerUrl+'/update_controller/tbl_cummulative_assessment_result';
  
          
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='info'){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                
                  setNotice({...notice,  isLoading: false })
                  
              })   
  }
    




  
const  handleSubmitFile=(event)=>{ 
    event.preventDefault(); 

    if(!csvFile.studentCsv){
        //errors.studentCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', 'You have not choose any file to upload')
    }else{
    
    const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append('studentCsv', csvFile.studentCsv);

     setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/update_controller/importassesment'

      axios.post(url, fd, config).then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{ 
            setNotice({...notice,  isLoading: false}) 
     setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 800KB)'}); 
     document.getElementById('studentCsv').value=''
        }) 
} 

}

const  handleFile = function(fieldName){
    return function(newValue){  
       
        setErrors({...errors, [fieldName]:''})
   readURL(newValue);
   }
   
} 


const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
        var fileName = document.getElementById(PreviewImage).value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    

    if (extFile==="csv" || extFile==="CSV"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 0.9) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 800 Kb';
       document.getElementById(input.target.name).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
               const value = input.target.files[0]
        setCsvFile({...csvFile, studentCsv:value, fileName:value.name})
       
        }
    }
}else{

    displayMessage = 'Only csv|CSV  files are allowed with maximum of 500kb'
    document.getElementById(input.target.name).value = '';
    setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 800Kb)'})
}  

if(displayMessage.length > 0){
    Alerts('Error!', 'danger', displayMessage)
  }

} 


    const handleReset=()=>{
        setResult({ 
            studentClass:[],
            examtype:[],
            isMusic:false,
        student:{value:'', label:'', studentName:''},
            orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
            })
    
            setStudentList([])
    }



    useEffect(()=>{
        fetchAssessment()
        fetchExamType()
        fetchAssementGrade() 
        fetchCategory()
    },[]);
  

        return ( <Template menu="Academics" submenu='Cummulative Assessment Entry'> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
        <div  className="main-body">
                     <div className="page-wrapper">
                      
     
             <PageHeader title="Cummulative Assessment Entry">
         <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                                 </li>
                                 <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                         <li className="breadcrumb-item"><a href="#!">Assessment  Result </a> </li>
                         </PageHeader>
     {/* The Page Body start here */}
                     <div className="page-body">
     
     
         <div className="row">
         <div className="col-sm-12">
         <div className="card z-depth-0">
         
                         <div className="card-header">
                             <h5>Cummulative Assessment Result Entry</h5>
                             <div className="card-header-right">
                                 <ul className="list-unstyled card-option">
                                     <li><i className="feather icon-maximizes full-card"></i></li>
                                     <li><i className="feather icon-minus minimize-card"></i></li>
                                     <li><i className="feather icon-trash-2 close-card"></i></li>
                                 </ul>
                             </div>
                         </div>
             <div className="card-block">	
             <div className="container">
                         
            
     <div style={{marginTop: '1px'}}>
            <div className="row setup-content" id="examType">
               
            <div className="col-md-12">
     
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="card z-depth-0">
     
           
        <div className="card-block">
           
       <div className="row">
          
          <section className="col-md-6">
                <div className="form-group">
              <label> Exam Type <span style={{color:'red'}}>*</span></label><a href="/exam/result_setup" className="pull-right" >Add more exam</a>
              <Select  options={examtype&&examtype.map((list, idx)=> {
                                    return {key:idx, value: list.examCode, label: list.exam }
                                  })
                             } 
                            
     onChange={handleSelectExam} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>
     
                 </div>
          
          </section>
          <section className="col-md-6">
     <div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            students&&students.map((list, idx)=> {
                               return {key:idx, value: list.studentCode, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 

          </div>
         

         </div>


         </div>	     
            
            </div>

            {studentList.length!==0&&result.student.length!==0?
            <div className="col-md-12">
         <div className="card z-depth-0">
     
     <div className="card-block">
  
     <div className="row">
     <div className="table-responsive">
         
          <table className="table">
              <thead>
                  <tr>
                  <th>SN</th> 
                  <th style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Assessment Name</th>
                      <th>Grade</th>   
                  </tr>
              </thead>
              <tbody>{studentList.filter(item=>item.studentCode===result.student.value).map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>


                    <td style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getAssessmentName(st.assessmentCode, 'assesmentName')} </td>


                    <td>
  
  
                    
                          <select name="grade" value={st.grade}  className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
  
                          <option  value=''>Select</option>
  
                          {assesmentGrade&&assesmentGrade.map((item,index)=>
                          <option key={index} value={item.rateKey}  >{item.rateKey+' - '+item.assesmentGrade}</option>)}
                          </select>
                          <textarea name='grade' placeholder='Enter Comment if Applicable' value={st.grade} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.grade}
      
      </textarea>
                      </td>
                  </tr>)}
              </tbody> 
          </table>
   </div>	 </div>   
  <div style={{color:'red'}}>{errors.classAssessment && errors.classAssessment}</div>
  <div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
  <div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
         </div>			
         </div>




         </div> :''}


            
            </div>	
                 
            </div>
         
     </div>
     
     {studentList.length!==0&&result.student.length!==0? <button onClick={handleSubmit} className={'btn btn-success pull-right'}  type="button" >Update Result</button>:''}
               </div>
                     
                     
                     
                     </div>
                    
                     </div>
                     </div>
                     </div> </div>
     
     
     
     
     {/* 
                     <div className="row  ">
                                     <div className="col-sm-6">
         <div className="card z-depth-0">
             <div className="card-block">
             <fieldset>
                 <div className="row">
                                         
                 <section className="col-md-12 ">
                 <div className="well well-sm alert alert-success border-success background-success ">
                         <h4 className="alert-heading ">Download Student File</h4>
                         
     <p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
     <span >The correct column order is (<b>
         Result Code, Admission Number, Student Name, Grade </b>)</span> <span >&amp; you must follow this.</span></p>
                         <hr className="simple" /><div className="text-align-left">
                             
                         <form method='POST' action={ServerUrl+'/csvSample_controller/assesment'} target='_blank'>
             <input type='hidden' name='classAssessment' value ={result.classAssessment.value} />
                     <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='sectionID' value ={sectionID} />
                     <input type='hidden' name='className' value ={result.examtype.label +' '+getClassName(classID,sectionID,schoolClass)+' - '+result.classAssessment.label} />
                     <input type='hidden' name='orderBy' value ={result.orderBy.value} />
                     
                     <input type='hidden' name='jwt' value ={Token} />
                    
             <button type="submit" className="display-none"  id="exportExcel"> </button>
            
          <button type="button" onClick={DownloadStudentList} className="btn btn-sm btn-inverse"><i className="fa fa-download"></i> Download File</button>
             </form>
             
                         </div>
                     </div>					
                     </section>
                                             </div>
                                             
                     </fieldset>
                     
                                 </div>
                                 </div>
                                 </div>
             
         
         
     
         <div className="col-sm-6">
         <div className="card z-depth-0">
         
             <div className="card-block">
     
     
             <div className="alert alert-success border-success">
                             <fieldset className="well well-sm">
                             <h4 className="alert-heading ">Upload file with .csv extension</h4>
                                 <section>
                                 <div className="form-group">
                                 
                         <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control" name="studentCsv" type="file" accept=".csv" /> 
                             <div className="note" style={{color:'red'}}>
                 {csvFile.fileName}
             </div>	
                                             </div>          
                                             
                                 </section>
                             </fieldset></div>
             <footer>
                 <button type="button" id="submit" onClick={handleSubmitFile} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Student Result</button>
             </footer>
     
         </div>
     
     </div>
     
     </div></div> */}
     
     
                     </div>
               {/* The Page Body close here */}
     
                 </div>
                 </div>
     
     </Template>  );
             
     }

export default React.memo(CummulativeAssessment) 