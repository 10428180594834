import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {

    
const clearCookies=()=>{
	Cookies.remove('pageclkysd')	
 Cookies.remove('pagesckayd')

	Cookies.remove('pagetkauscd')	
 Cookies.remove('pagetkamtk')
 Cookies.remove('pagetkarfsh')
 Cookies.remove('pagetkstnm')
 Cookies.remove('pageclkysd')	
 Cookies.remove('pagesckayd')
}

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut