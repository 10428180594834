import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
export const classID = Cookies.get('pageclkysd')?Cookies.get('pageclkysd'):'null'
export const sectionID = Cookies.get('pagesckayd')?Cookies.get('pagesckayd'):'null'
export const staffCode= Cookies.get('pagetkauscd')?Cookies.get('pagetkauscd'):'null'
export const staffName = Cookies.get('pagetkstnm')?Cookies.get('pagetkstnm'):'null'
export const Token = Cookies.get('pagetkamtk')?Cookies.get('pagetkamtk'):undefined
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'



//export  const ServerUrl = 'http://192.168.64.3/kayus/page/controller';
//export  const ImagesUrl = 'http://192.168.64.3/kayus/page/images'; 


export  const ServerUrl = 'https://app.pageschoolsportal.org/controller';
export  const ImagesUrl = 'https://app.pageschoolsportal.org/images';
export  const StudentUrl = 'https://learners.pageschoolsportal.org';  
export  const LocalUrl = 'localhost';
export const schoolName = 'PAGE INTERNATIONAL SCHOOL' 




//export  const LocalUrl = 'localhost';

 


