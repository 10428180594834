import React, {useEffect, useState} from 'react'
import { compare } from './globalFunction';
import { useLocation } from 'react-router-dom'

const Navbar = (props)=>{
    
    let location =  useLocation()

    const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });
const [isDisplay, setIsDisplay] = useState({ "0": true });
/* {
  "menuName": "Fees",
  "description": "Fees Management",
  "menuIcon": "fa  fa-fw fa-money",
  "status": true,
  "menuUrl": "",
  "order": "4",
  "ID": "ze0bdq77p",
  "submenu": [
    {
      "subName": "Fees Payment",
      "subUrl": "/fees/fees_payment",
      "subDescription": "Fees Payment",
      "status": true,
      "ID": "tb3un1zuz",
      "subsubmenu": []
    },
    {
      "subName": "Class Fees",
      "subUrl": "/fees/class_fees",
      "subDescription": "Class Fees",
      "status": true,
      "ID": "jw6quca1l",
      "subsubmenu": []
    },
    {
      "subName": "Report",
      "subUrl": "",
      "subDescription": "Report",
      "status": true,
      "ID": "n2u0iyhke",
      "subsubmenu": [
        {
          "subsubName": "Print Fees Voucher",
          "status": true,
          "subsubUrl": "/fees/print_voucher",
          "subsubDescription": "Printout fees teller",
          "ID": "kqhnijls9"
        },
        {
          "subsubName": "Payment History",
          "status": true,
          "subsubUrl": "/fees/payment_history",
          "subsubDescription": "Display fees payment report",
          "ID": "ie6nhm60s"
        },
        {
          "subsubName": "Date Wise Payment",
          "status": true,
          "subsubUrl": "/fees/date_wise_report",
          "subsubDescription": "Display fees report by date",
          "ID": "fgdfypco3"
        },
        {
          "subsubName": "Student Fees Register",
          "status": true,
          "subsubUrl": "/fees/fees_register",
          "subsubDescription": "List of student fees",
          "ID": "anw22lzzc"
        }
        
      ]
    }
  ]
}, 
    {
      "menuName": "Conference",
      "description": "Staff Conference",
      "menuIcon": "fa  fa-fw fa-users",
      "status": true,
      "menuUrl": "/conference",
      "order": "12",
      "ID": "a7oktk1csq",
      "submenu": []
    },{
      "menuName": "Payroll",
      "description": "Payroll System",
      "menuIcon": "fa  fa-fw fa-briefcase",
      "status": true,
      "menuUrl": "",
      "order": "8",
      "ID": "gcnfuq98o",
      "submenu": [
        {
          "subName": "Salary Structure",
          "subUrl": "/payroll/salary_structure",
          "subDescription": "",
          "status": true,
          "ID": "wuw1ncg44",
          "subsubmenu": []
        },
        {
          "subName": "Apply Leave",
          "subUrl": "/payroll/apply_leave",
          "subDescription": "",
          "status": true,
          "ID": "oxgrfw2ro",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "",
          "status": true,
          "ID": "0moxoofiq",
          "subsubmenu": [
            {
              "subsubName": "Staff Salary",
              "status": true,
              "subsubUrl": "/payroll/staff_salary",
              "subsubDescription": "",
              "ID": "gukknr5in"
            },
            {
              "subsubName": "Print Pay Slip",
              "status": true,
              "subsubUrl": "/payroll/payslip",
              "subsubDescription": "",
              "ID": "8vo8jak6u"
            }
          ]
        }
      ]
    }, */
   const menuList = [
    {
      "menuName": "Dashboard",
      "description": "Display website usage statistics",
      "menuIcon": "fa fa-fw fa-dashboard",
      "status": true,
      "menuUrl": "/dashboard",
      "order": 1,
      "ID": "aw4jkqzjw",
      "submenu": []
    },
    {
      "menuName": "Student",
      "description": "List of Students",
      "menuIcon": "fa  fa-graduation-cap",
      "status": true,
      "menuUrl": "",
      "order": "2",
      "ID": "y5wd8myrt",
      "submenu": [
       
        {
          "subName": "View Student",
          "subUrl": "/students/view_students",
          "subDescription": "Display all students",
          "status": true,
          "ID": "sq2svu5l0",
          "subsubmenu": []
        },
        {
          "subName": "Promote Student",
          "subUrl": "/students/promotion",
          "subDescription": "Promotion of students",
          "status": true,
          "ID": "dw91uq08n",
          "subsubmenu": []
        },
        {
          "subName": "Transfer Student",
          "subUrl": "/students/transfer_class",
          "subDescription": "Transfer student to another class",
          "status": true,
          "ID": "305k26j16",
          "subsubmenu": []
        },
        {
          "subName": "Student Review",
          "subUrl": "/students/review",
          "subDescription": "Student performance report",
          "status": true,
          "ID": "nmo92nquy",
          "subsubmenu": []
        },
        {
          "subName": "Student Birthdays",
          "subUrl": "/students/birthdays",
          "subDescription": "Student birthday report",
          "status": true,
          "ID": "nmo9wedr",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Academics",
      "description": "Academics Management",
      "menuIcon": "fa  fa-fw fa-file-text",
      "status": true,
      "menuUrl": "",
      "order": "3",
      "ID": "cw13tmtoo",
      "submenu": [
        {
            "subName": "Exam Schedule",
            "subUrl": "/exam/exam_schedule",
            "subDescription": "Exam Schedule",
            "status": true,
            "ID": "dgkjpet2e",
            "subsubmenu": []
          },
        {
          "subName": "Mock Result Entry",
          "subUrl": "/exam/mock_result_entry",
          "subDescription": "Enter student mock score",
          "status": true,
          "ID": "dgket2e",
          "subsubmenu": []
        },
        {
          "subName": "Terminal Result Entry",
          "subUrl": "/exam/result_entry",
          "subDescription": "Enter student exam score",
          "status": true,
          "ID": "dgkjpet2e",
          "subsubmenu": []
        },
        {
          "subName": "Assessment Entry",
          "subUrl": "/exam/assesment_entry",
          "subDescription": "Enter student Standards  score",
          "status": true,
          "ID": "nlfd0a7uf",
          "subsubmenu": []
        },
        {
          "subName": "Cummulative Assessment Entry",
          "subUrl": "/exam/cummulative_assesment_entry",
          "subDescription": "Enter student assessment  score",
          "status": true,
          "ID": "nlfdy7uf",
          "subsubmenu": []
        },
        {
          "subName": "Comment Entry",
          "subUrl": "/exam/result_comment",
          "subDescription": "Enter student remark",
          "status": true,
          "ID": "dgkjpfet2e",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "Reports",
          "status": true,
          "ID": "vaq8tol12",
          "subsubmenu": [
            {
              "subsubName": "Subject Marks",
              "status": true,
              "subsubUrl": "/exam/subject_marks",
              "subsubDescription": "Subject result report",
              "ID": "8fqh940kw"
            },
            {
              "subsubName": "Class Marks",
              "status": true,
              "subsubUrl": "/exam/class_marks",
              "subsubDescription": "Class result report",
              "ID": "sql5dk46g"
            },
            {
              "subsubName": "Half Term Result",
              "status": true,
              "subsubUrl": "/exam/mid_term_result",
              "subsubDescription": "Printout Mid term Result",
              "ID": "pesgi1bo"
            },
            {
              "subsubName": "Terminal Result",
              "status": true,
              "subsubUrl": "/exam/view_result",
              "subsubDescription": "Printout Terminal Result",
              "ID": "pa0sgi1bo"
            },
            {
              "subsubName": "Student Transcript",
              "status": true,
              "subsubUrl": "/exam/student_transcript",
              "subsubDescription": "Student transcript report",
              "ID": "wuji9suog"
            }
          ]
        }
      ]
    },
    
    
    {
      "menuName": "Attendance",
      "description": "Staff & Student attendance",
      "menuIcon": "fa  fa-fw fa-calendar",
      "status": true,
      "menuUrl": "",
      "order": "6",
      "ID": "6uabdn9wm",
      "submenu": [
        
        {
          "subName": "Display Staff Attendance",
          "subUrl": "/attendance/display_staff_attendance",
          "subDescription": "",
          "status": true,
          "ID": "fbrriuz2b",
          "subsubmenu": []
        },
        {
          "subName": "Student Attendance",
          "subUrl": "/attendance/student_attendance",
          "subDescription": "",
          "status": true,
          "ID": "kov9cnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Display Student Attendance",
          "subUrl": "/attendance/display_student_attendance",
          "subDescription": "",
          "status": true,
          "ID": "wp0in022b",
          "subsubmenu": []
        },
        {
          "subName": "Student Late Arrival & Early Departure",
          "subUrl": "/attendance/student_arrival_departure",
          "subDescription": "",
          "status": true,
          "ID": "i2p5y7thw",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "",
          "status": true,
          "ID": "bhjbmp6s5",
          "subsubmenu": [
            {
              "subsubName": "Staff Summary",
              "status": true,
              "subsubUrl": "/attendance/staff_attendance_summary",
              "subsubDescription": "",
              "ID": "spfux4rxg"
            },
            {
              "subsubName": "Staff Working Hours",
              "status": true,
              "subsubUrl": "/attendance/staff_working_hours",
              "subsubDescription": "",
              "ID": "a5nsgp3u6"
            },
            {
              "subsubName": "Student Summary",
              "status": true,
              "subsubUrl": "/attendance/student_attendance_summary",
              "subsubDescription": "",
              "ID": "39alrn2xb"
            }
          ]
        }
      ]
    },
    
    
    {
      "menuName": "Library",
      "description": "Library books",
      "menuIcon": "fa  fa-fw fa-book",
      "status": true,
      "menuUrl": "",
      "order": "9",
      "ID": "nhn0mxac0",
      "submenu": [
        
        {
          "subName": "Book Availability",
          "subUrl": "/library/books",
          "subDescription": "",
          "status": true,
          "ID": "ks2xr2k0n",
          "subsubmenu": []
        },
        {
          "subName": "E-Library",
          "subUrl": "/library/elibrary",
          "subDescription": "",
          "status": true,
          "ID": "dar95nzqk",
          "subsubmenu": []
        },
        {
          "subName": "Library Due",
          "subUrl": "/library/books_report",
          "subDescription": "",
          "status": true,
          "ID": "507rg60si",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Timetable",
      "description": "Class Timetable",
      "menuIcon": "fa  fa-fw fa-table",
      "status": true,
      "menuUrl": "",
      "order": "10",
      "ID": "vlhb61kv1",
      "submenu": [
        
        {
          "subName": "Automatic Timetable Setup",
          "subUrl": "/timetable/setup",
          "subDescription": "",
          "status": true,
          "ID": "piefyfrxu",
          "subsubmenu": []
        },
        {
          "subName": "Timetable Day Wise",
          "subUrl": "/timetable/setup_class_timetable",
          "subDescription": "",
          "status": true,
          "ID": "oaki2zqnc",
          "subsubmenu": []
        },
        {
          "subName": "Class Lesson Plan",
          "subUrl": "/timetable/class_lesson_plan",
          "subDescription": "",
          "status": true,
          "ID": "uaakk7drx",
          "subsubmenu": []
        },
        {
          "subName": "Staff Schedule",
          "subUrl": "/timetable/staff_schedule",
          "subDescription": "",
          "status": true,
          "ID": "kgtzscyxe",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "",
          "status": true,
          "ID": "isfh1c37g",
          "subsubmenu": [
            {
              "subsubName": "Holiday List",
              "status": true,
              "subsubUrl": "/timetable/holidays_list",
              "subsubDescription": "",
              "ID": "pjnblvtgh"
            },
            {
                "subsubName": "Lesson Plan",
                "status": true,
                "subsubUrl": "/timetable/lesson_plan",
                "subsubDescription": "",
                "ID": "pjnblvtgh"
              },
            {
              "subsubName": "View Timetable",
              "status": true,
              "subsubUrl": "/timetable/view_timetable",
              "subsubDescription": "",
              "ID": "622862a31"
            }
          ]
        }
      ]
    },
    {
      "menuName": "Noticeboard",
      "description": "School Noticeboard",
      "menuIcon": "fa  fa-fw fa-bar-chart",
      "status": true,
      "menuUrl": "/staff/noticeboard",
      "order": "13",
      "ID": "a7otk1csq",
      "submenu": []
    },
    {
        "menuName": "My Signature",
        "description": "Staff Signature",
        "menuIcon": "fa  fa-fw fa-image",
        "status": true,
        "menuUrl": "/staff/signature",
        "order": "14",
        "ID": "a7otk1csq",
        "submenu": []
      }
  ]

const handleToggleDisplaySubmenu=(main, sub)=>{
   // handleToggleDisplay(main)
   if(sub!=='0'){
   
    setIsDisplay({[main]: true});
    setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
}else{
    setIsDisplay({[main]: !isDisplay[main]})
}
}


const fetchContent =()=>{

    let response = menuList.sort(compare)
    let menu = response.filter(item=>item.menuName===props.menu)[0]
    
     let submenu = menu.submenu.length!==0 && props.submenu !==''?menu.submenu.filter(item=>item.subName===props.submenu)[0]:{"ID":"0"}

     setIsDisplaySubmenu({[submenu.ID]:true})
     setIsDisplay({[menu.ID]:true})
   // setIsDisplaySubsubmenu({[subsub.ID]: true});
    //setMenu(response)

}

let activeHasmenuOpen ='pcoded-hasmenu pcoded-trigger active'

useEffect(()=>{
    fetchContent() 
    },[]);

    return (  
        <nav className="pcoded-navbar no-print"  >
        <div className="pcoded-inner-navbar main-menu " >
            <div className="pcoded-navigatio-lavel" menu-title-theme="theme5" ></div>
            
             <ul className="pcoded-item pcoded-left-item no-print">
               {menuList.map((data, index)=>
        <li  key={index} dropdown-icon={data.submenu.length!==0?"style1":''} subitem-icon={data.submenu.length!==0?"style1":''}  className={data.submenu.length ===0 && props.menu===data.menuName?'active':
           
            isDisplay[data.ID] && props.menu===data.menuName && data.submenu.length!==0?activeHasmenuOpen:
            props.menu===data.menuName && data.submenu.length!==0?'pcoded-hasmenu active':
            isDisplay[data.ID] && data.submenu.length !==0 ?'pcoded-hasmenu pcoded-trigger':
        
        data.submenu.length !==0?'pcoded-hasmenu':  ''}  >
                    <a href={data.submenu.length!==0?'#!':data.menuUrl} title={data.menuName} onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}>
                        <span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                        <span className="pcoded-mtext">{data.menuName}</span>
                    </a>
                    {data.submenu.length!==0?
                    <ul className="pcoded-submenu">
                  {data.submenu.map((smenu, sid)=>
          <li key ={sid} 

    className={smenu.subsubmenu.length ===0 && props.submenu===smenu.subName?'active':
    
        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName?activeHasmenuOpen: 
        
        isDisplay[data.ID] && isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName ?'pcoded-hasmenu pcoded-trigger active': 
        

        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0?'pcoded-hasmenu pcoded-trigger':
         smenu.subsubmenu.length !==0?'pcoded-hasmenu':
                        ''}  
                        dropdown-icon={smenu.subsubmenu.length!==0?"style1":''} subitem-icon={smenu.subsubmenu.length!==0?"style1":''}
                        
                        >
                    <a href={smenu.subsubmenu.length!==0?'#!':smenu.subUrl} 
                        onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>
                        <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                        <span className="pcoded-mtext">{smenu.subName}</span>
                    </a>
                    {smenu.subsubmenu.length!==0?
                    <ul className="pcoded-submenu">

                        {smenu.subsubmenu.map((subsub, k)=>
                                <li   className={location.pathname===subsub.subsubUrl?'active':'' } key={k}  >
                                    <a href={subsub.subsubUrl}>
                                        <span className="pcoded-mtext">{subsub.subsubName}</span>
                                    </a>
                                </li>)}

                            </ul>:''}
                        </li>)}
                       
                    </ul>:''}
                </li>)}
                <li className="">
                    <a href="/logout">
                    <span className="pcoded-micon"><i className="feather icon-power"></i></span>
                    <span className="pcoded-mtext">Log Out</span>
                    </a>
                </li>
            </ul> 
 
        </div>
    </nav> );
}
export default React.memo(Navbar)